<template>
    <p v-tooltip="quantidade" class="text-7xl font-medium m-0 mt-4 justify-content-center w-fit">{{ quantidadeFormatada }}</p>
</template>

<script>
import formatarNumero from '@/utils/FormatadorNumero';

export default {
    props: {
        quantidade: { type: String, required: true },
        formatarValor: { type: Boolean, required: true }
    },
    computed: {
        quantidadeFormatada() {
            return this.formatarValor ? formatarNumero(this.quantidade) : this.quantidade;
        }
    }
};
</script>

<template>
    <i class="text-primary pi pi-info-circle" style="font-size: 1.5rem; height: max-content" v-tooltip.top="descricao" />
</template>
<script>
export default {
    props: {
        descricao: { type: String, required: true }
    }
};
</script>

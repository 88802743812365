<template>
    <div
        v-show="!loading"
        class="w-full p-5 border-round-md border-1 border-300 border lg:overflow-y-hidden lg:overflow-x-auto md:overflow-y-hidden md:overflow-x-auto sm:overflow-y-hidden sm:overflow-x-auto overflow-y-hidden overflow-x-auto"
    >
        <HeaderCard :loading="loading" :tittle="titulo" @onLoad="load" :comFiltro="comFiltro"/>
        <div class="flex flex-row justify-content-around align-items-center xl:column-gap-3">
            <Chart v-if="!dadosZerados" type="doughnut" :data="chartData" :options="chartOptions" class="h-20rem w-20rem" />
            <div  v-if="!dadosZerados">
                <div class="flex flex-column row-gap-1">
                    <span class="text-5xl font-medium">
                            {{ quantidadeTotal }}
                    </span>
                    {{ labelTotal }}
                </div>     
                <ScrollPanel class="h-20rem">
                    <LegendaPersonalizada
                        :dadosPercentagem="dadosPercentagem"
                        v-if="legendaPersonalizada"
                        :colors="cores"
                        :labels="this.labels"
                        :quantidades="this.quantidades"
                        :porcentagem="this.porcentagem"
                    />
                </ScrollPanel>
            </div>
            <MensagemSemDados v-else height-class="h-20rem" />
        </div>
    </div>

    <div v-show="loading" class="w-full h-27rem">
        <Skeleton width="100%" height="100%" />
    </div>
</template>

<script>
import LegendaPersonalizada from './LegendaPersonalizada.vue';
import { useFiltro } from '../composables/filtro';
import { getClientBase } from '@/services/http';
import MensagemSemDados from './MensagemSemDados.vue';
import formatarNumero from '@/utils/FormatadorNumero';
import HeaderCard from '../../relatorios-e-indicadores/components/HeaderCard.vue';

export default {
    components: { MensagemSemDados, HeaderCard, LegendaPersonalizada },
    props: {
        customerIds: {
            type: Array,
            required: true
        },
        labelTotal: {
            type: String,
            default: 'Total'
        },
        grupoIds: {
            type: Array,
            required: true
        },
        customerBranchIds: {
            type: Array,
            required: true
        },
        titulo: {
            type: String,
            required: true
        },
        legendaPersonalizada: {
            type: Boolean,
            default: false
        },
        dadosPercentagem: {
            type: Boolean,
            default: false
        },
        comFiltro: {
            tyoe: Boolean,
            default: true
        },
    },
    setup() {
        return useFiltro();
    },
    data() {
        return {
            chartData: null,
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            chartOptions: {
                cutout: '50%',
                plugins: {
                    legend: {
                        display: false
                    }
                }
            },
            dados: [],
            loading: false,
            path: ''
        };
    },
    computed: {
        quantidades() {
            return this.dados.map(({ quantidade }) => quantidade);
        },
        porcentagem() {
            return this.dados.map(({ porcentagem }) => porcentagem);
        },
        labels() {
            return this.dados.map(({ nome }) => nome);
        },
        cores() {
            return this.dados.map(({ cor }) => cor);
        },
        dadosZerados() {
            return this.quantidades.every((item) => !item);
        },
        quantidadeTotal() {
            return formatarNumero(this.quantidades.reduce((total, quantidade) => Number(total) + Number(quantidade), 0));
        },
    },
    watch: {
        quantidade() {
            this.queryData(this.path);
        },
        periodicidade() {
            this.queryData(this.path);
        }
    },
    methods: {
        setChartData() {
            return {
                labels: this.labels,
                datasets: [
                    {
                        data: this.quantidades,
                        backgroundColor: this.cores,
                        hoverBackgroundColor: this.cores
                    }
                ]
            };
        },
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            if (this.path) {
                await this.queryData(this.path);
            }
        },
        async queryData(path) {
            this.loading = true;
            this.path = path;
            const { data } = await getClientBase().get(path, {
                params: {
                    filtros: JSON.stringify({
                        periodicidade: this.periodicidade,
                        dataInicio: this.dataInicio,
                        dataFim: this.dataFim,
                        customerIds: this.customerIds?.length ? this.customerIds : undefined,
                        grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                        customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined
                    })
                }
            });
            this.dados = data;
            this.chartData = this.setChartData();
            this.loading = false;
        }
    }
};
</script>

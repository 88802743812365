<template>
    <div class="header-cnt flex flex-row align-items-center justify-content-between w-full column-gap-4">
        <span class="flex align-items-center column-gap-2">
            <p class="text-xl font-normal m-0 vertical-align-top">{{ title }}</p>
            <slot name="afterTitle"></slot>
        </span>
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, required: true }
    }
};
</script>

<style lang="scss" scoped>
.header-cnt {
    height: 36px;
}
</style>

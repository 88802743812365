<template>
    <h5 class="flex justify-content-center align-items-center w-full" :class="heightClass">Não há dados para visualização</h5>
</template>
<script>
export default {
    props: {
        heightClass: {
            type: String,
            required: true
        }
    }
};
</script>

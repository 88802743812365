<template>
    <div>
        <Button
            icon="pi pi-filter"
            label="Filtrar"
            iconPos="right"
            @click="(event) => $refs.painelFiltro.toggle(event)"
            :loading="loading"
            class="p-button-outlined p-button-secondary"
        />
        <PainelFiltro ref="painelFiltro" />
    </div>
</template>

<script>
import PainelFiltro from './PainelFiltro.vue';

export default {
    components: { PainelFiltro },
    props: {
        icon: { type: String, default: 'pi pi-filter' }
    },
    data() {
        return {
            loading: false
        };
    }
};
</script>

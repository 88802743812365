<template>
    <OverlayPanel ref="op" :dismissable="true">
        <div class="flex flex-column my-2">
            <label>Referência:</label>
            <Dropdown v-model="periodicidade" :options="optionsPeriodicidade" optionValue="value" optionLabel="label" placeholder="Periodicidade" />
        </div>
        <div class="flex flex-column my-2">
            <label>Quantidade:</label>
            <InputNumber :showButtons="true" v-model="quantidade" />
        </div>
        <div class="text-right">
            <Button @click="save">Filtrar</Button>
        </div>
    </OverlayPanel>
</template>

<script>
export default {
    data() {
        return {
            periodicidade: null,
            optionsPeriodicidade: [
                { label: 'Dia', value: 'day' },
                { label: 'Semana', value: 'week' },
                { label: 'Mês', value: 'month' },
                { label: 'Trimestre', value: 'quarter' },
                { label: 'Ano', value: 'year' }
            ],
            quantidade: null
        };
    },
    inject: ['getFilterSettings', 'changeFilterSettings'],
    methods: {
        toggle(event) {
            this.$refs.op.toggle(event);
        },
        save(event) {
            const params = {
                periodicidade: this.periodicidade,
                quantidade: this.quantidade
            };

            this.changeFilterSettings(params);

            this.toggle(event);
        }
    },
    beforeMount() {
        const { periodicidade, quantidade } = this.getFilterSettings();
        this.periodicidade = periodicidade;
        this.quantidade = quantidade;
    }
};
</script>

<template>
    <div class="w-full p-5 border-round-md border-1 border-300 h-16rem">
        <PanelHeader :title="info.nome">
            <Informativo :descricao="info.descricao" />
        </PanelHeader>
        <IndicadorQuantitativo :quantidade="info.quantidade" :formatar-valor="formatarValor" />
    </div>
</template>

<script>
import PanelHeader from '../../PanelHeader.vue';
import Informativo from '../../Informativo.vue';
import IndicadorQuantitativo from '../../IndicadorQuantitativo.vue';

export default {
    components: { PanelHeader, Informativo, IndicadorQuantitativo },
    props: {
        info: { type: Object, required: true },
        formatarValor: { type: Boolean, required: true }
    },
    data() {
        return {
            loading: true
        };
    },
    methods: {
        load() {
            this.loading = false;
        }
    }
};
</script>

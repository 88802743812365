<template>
    <div class="flex flex-column row-gap-1">
        <div v-for="(label, index) in labels" :key="index" class="mt-2" style="cursor: default">
            <span class="text-5xl">
                {{ getQuantidade(index) }}
            </span>
            <span class="ml-2 text-xl text-800">
                {{ `${((quantidades[index] / quantidadeTotalSemFormatacao) * 100 || 0).toFixed(0)}%` }}
            </span>
            <span class="flex flex-row align-items-center column-gap-2">
                <span style="width: 10px; height: 10px" :style="{ backgroundColor: colors[index] }" />
                <span>{{ label }}</span>
            </span>
        </div>
    </div>
</template>

<script>
import formatarNumero from '@/utils/FormatadorNumero';

export default {
    props: {
        labels: {
            type: Array,
            required: true
        },
        labelTotal: {
            type: String,
            default: 'Total'
        },
        colors: {
            type: Array,
            required: true
        },
        quantidades: {
            type: Array,
            required: true
        },
        formatarValor: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        quantidadeTotal() {
            return formatarNumero(this.quantidades.reduce((total, quantidade) => Number(total) + Number(quantidade), 0));
        },
        quantidadeTotalSemFormatacao() {
            return this.quantidades.reduce((total, quantidade) => Number(total) + Number(quantidade), 0);
        }
    },
    methods: {
        getQuantidade(index) {
            return this.formatarValor ? formatarNumero(this.quantidades[index]) : this.quantidades[index];
        }
    }
};
</script>

<template>
    <template v-if="!loading">
        <QuantityPanel v-for="(item, index) in dados" :key="index" :info="item" :formatar-valor="formatarValor" />
    </template>
    <template v-else>
        <div v-for="n in 5" :key="n">
            <Skeleton height="16rem" />
        </div>
    </template>
</template>
<script>
import QuantityPanel from './components/QuantityPanel.vue';
import { getClientBase } from '@/services/http';

export default {
    components: {
        QuantityPanel
    },
    props: {
        customerIds: {
            type: Array
        },
        grupoIds: {
            type: Array
        },
        customerBranchIds: {
            type: Array
        },
        formatarValor: { type: Boolean, default: true }
    },
    data() {
        return {
            loading: false,
            dados: []
        };
    },
    methods: {
        async queryData(path) {
            this.loading = true;
            if (path !== 'mock') {
                const { data } = await getClientBase().get(path, {
                    params: {
                    filtros: JSON.stringify({
                        customerIds: this.customerIds?.length ? this.customerIds : undefined,
                        grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                        customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined
                        })
                    }
                });
                this.dados = data;
            } else {
                this.dados = [
                    {
                        nome: 'Aguardando agendamento',
                        descricao: 'Quantidade de agendamentos com status "Aguardando agendamento"',
                        quantidade: '2'
                    },
                    {
                        nome: 'Em processo de agendamento',
                        descricao: 'Quantidade de agendamentos com status "Em processo de agendamento"',
                        quantidade: '501'
                    },
                    {
                        nome: 'Dia da consulta',
                        descricao: 'Quantidade de agendamentos com status "Dia da consulta"',
                        quantidade: '12'
                    },
                    {
                        nome: 'Aguardando consulta',
                        descricao: 'Quantidade de agendamentos com status "Aguardando consulta"',
                        quantidade: '1'
                    }
                ];
            }
            this.loading = false;
        }
    }
};
</script>

<template>
    <div
        v-show="!loading"
        class="w-full p-5 border-round-md border-1 border-300 border lg:overflow-y-hidden lg:overflow-x-auto md:overflow-y-hidden md:overflow-x-auto sm:overflow-y-hidden sm:overflow-x-auto overflow-y-hidden overflow-x-auto"
    >
     <HeaderCard :loading="loading" :tittle="titulo" @onLoad="load" />
        <Chart v-if="!dadosZerados" type="line" :data="chartData" :options="chartOptions" class="h-18rem mt-4" :plugins="plugins" />
        <MensagemSemDados v-else height-class="h-17rem" />
    </div>

    <div v-show="loading" class="w-full h-27rem">
        <Skeleton width="100%" height="100%" />
    </div>
</template>

<script>
import moment from 'moment-timezone';
import { useFiltro } from '../composables/filtro';
import { getClientBase } from '@/services/http';
import MensagemSemDados from './MensagemSemDados.vue';
import formatarNumero from '@/utils/FormatadorNumero';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import HeaderCard from '../../relatorios-e-indicadores/components/HeaderCard.vue';

export default {
    components: {
        MensagemSemDados,
        HeaderCard
    },
    props: {
        customerIds: {
            type: Array,
            required: true
        },
        grupoIds: {
            type: Array,
            required: true
        },
        customerBranchIds: {
            type: Array,
            required: true
        },
        titulo: {
            type: String,
            required: true
        },
        cor: {
            type: String,
            default: '#1ba7af'
        },
        accumulate: { type: Boolean, default: false },
        formatarValor: { type: Boolean, default: true }
    },
    setup() {
        return useFiltro();
    },
    data() {
        return {
            chartData: null,
            chartOptions: null,
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            dados: [],
            formatsRange: {
                day: 'DD/MM/YYYY',
                week: 'DD/MM/YYYY',
                month: 'MMM/YYYY',
                quarter: 'MMM/YYYY',
                year: 'YYYY'
            },
            loading: false,
            path: '',
            plugins: [
                ChartDataLabels,
                {
                    beforeInit(chart) {
                        const originalFit = chart.legend.fit;

                        chart.legend.fit = function fit() {
                            originalFit.bind(chart.legend)();
                            this.height += 25;
                        };
                    }
                }
            ]
        };
    },
    computed: {
        customLabel() {
            if (this.accumulate) {
                return {
                    bodyAlign: 'center',
                    callbacks: {
                        beforeLabel: (context) => {
                            const total = context.dataset.data.reduce((acc, val) => acc + +val, 0);
                            const atual = +context.raw;
                            return `${((atual / total) * 100).toFixed(1)}%`;
                        },
                        label: (context) => {
                            const total = context.dataset.data.reduce((acc, val) => acc + +val, 0);
                            const atual = +context.raw;

                            return this.formatarValor ? `${formatarNumero(atual)} / ${formatarNumero(total)}` : `${atual} / ${total}`;
                        }
                    }
                };
            }
            return {
                callbacks: {
                    label: (context) => {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += this.formatarValor ? formatarNumero(context.parsed.y) : context.parsed.y;
                        }
                        return label;
                    }
                }
            };
        },
        dadosZerados() {
            return this.chartData ? this.chartData.datasets[0]?.data.every(({ item }) => +item === 0) : true;
        }
    },
    watch: {
        quantidade() {
            this.queryData(this.path);
        },
        periodicidade() {
            this.queryData(this.path);
        }
    },
    methods: {
        setChartData() {
            return {
                labels: this.dados
                    .map((item) => this.formatRange(item.inclusao))
                    .reduce((acc, item) => (acc.includes(item) ? acc : [...acc, item]), []),
                datasets: [
                    {
                        data: this.dados.map((item) => item.quantidade?.toLocaleString('pt-BR')),
                        borderColor: this.cor,
                        backgroundColor: this.cor,
                        tension: 0.4
                    }
                ]
            };
        },
        formatRange(item) {
            return moment.tz(item, 'UTC').format(this.formatsRange[this.periodicidade]);
        },
        setChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
            const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

            return {
                maintainAspectRatio: false,
                aspectRatio: 0.6,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        displayColors: false,
                        ...this.customLabel
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'end',
                        backgroundColor: '#495057',
                        borderRadius: 2,
                        color: 'white',
                        font: {
                            weight: 'bold'
                        },
                        padding: 8
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    y: {
                        ticks: {
                            color: textColorSecondary,
                            callback: (value) => {
                                return this.formatarValor ? formatarNumero(value) : value;
                            }
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    }
                }
            };
        },
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            this.statusIds = params.statusIds;
            if (this.path) {
                await this.queryData(this.path);
            }
        },
        async queryData(path) {
            this.loading = true;
            this.path = path;
            const { data } = await getClientBase().get(path, {
                params: {
                    filtros: JSON.stringify({
                        periodicidade: this.periodicidade,
                        dataInicio: this.dataInicio,
                        dataFim: this.dataFim,
                        customerIds: this.customerIds?.length ? this.customerIds : undefined,
                        grupoIds: this.grupoIds?.length ? this.grupoIds : undefined,
                        customerBranchIds: this.customerBranchIds?.length ? this.customerBranchIds : undefined
                    })
                }
            });
            this.dados = data;
            this.chartData = this.setChartData();
            this.chartOptions = this.setChartOptions();
            this.loading = false;
        }
    }
};
</script>
